import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 960.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">

<path d="M4867 8094 c-23 -190 -22 -241 6 -295 35 -66 98 -101 191 -107 42 -2
88 0 108 7 53 17 106 76 123 135 16 58 51 333 43 341 -6 7 -108 19 -108 14 0
-2 -9 -75 -20 -161 -25 -210 -41 -238 -134 -238 -55 0 -101 34 -110 83 -3 18
3 102 13 187 11 85 18 156 16 158 -2 2 -27 6 -57 9 l-54 5 -17 -138z"/>
<path d="M4461 8209 c-99 -19 -161 -82 -161 -164 0 -87 54 -126 198 -144 45
-6 91 -15 102 -21 20 -11 26 -45 12 -67 -21 -34 -154 -45 -219 -18 -19 8 -36
15 -38 15 -1 0 -7 -19 -14 -42 -16 -51 -6 -61 77 -79 71 -14 153 -4 219 27 81
39 117 107 93 177 -21 66 -67 89 -205 107 -96 12 -126 33 -107 75 7 14 23 30
38 35 32 13 119 13 161 1 31 -9 33 -8 43 27 15 57 14 60 -40 71 -58 12 -96 13
-159 0z"/>
<path d="M4119 8148 c-10 -8 -29 -76 -49 -170 -18 -87 -36 -158 -39 -158 -3 0
-62 47 -131 105 -69 57 -129 104 -135 104 -5 0 -18 -4 -28 -7 -14 -6 13 -33
132 -132 148 -123 151 -126 176 -193 14 -38 25 -75 25 -83 0 -18 39 -18 46 0
3 8 -6 47 -21 87 l-26 74 41 179 c22 98 40 185 40 192 0 17 -12 18 -31 2z"/>
<path d="M5522 8048 c-59 -167 -135 -399 -131 -402 2 -1 67 -25 144 -52 113
-39 153 -49 207 -49 63 0 70 2 99 32 26 26 33 41 36 85 4 45 0 57 -21 83 l-24
30 30 15 c46 22 70 61 70 110 -1 84 -50 120 -242 185 -74 25 -135 45 -136 45
-1 0 -15 -37 -32 -82z m188 -63 c81 -27 100 -42 100 -79 0 -40 -16 -56 -55
-56 -43 0 -155 39 -155 54 0 29 33 107 44 103 6 -2 36 -12 66 -22z m-56 -201
c84 -28 116 -52 116 -85 0 -65 -48 -75 -172 -33 l-76 25 20 60 c11 32 23 59
26 59 4 0 42 -12 86 -26z"/>
<path d="M3515 7914 c-10 -11 -14 -27 -11 -47 3 -18 8 -114 12 -215 l6 -182
-39 17 c-21 10 -107 53 -190 96 -84 42 -159 77 -168 77 -34 0 -24 -33 44 -137
39 -59 98 -152 132 -205 34 -54 67 -98 73 -98 43 1 35 20 -73 189 -61 94 -108
171 -106 171 3 0 82 -39 176 -86 94 -47 178 -83 186 -80 12 5 13 22 9 93 -3
49 -8 140 -12 203 l-6 115 30 -45 c77 -119 142 -219 169 -261 27 -43 31 -45
48 -33 23 17 26 10 -100 204 -45 69 -98 151 -118 183 -20 31 -39 57 -41 57 -3
0 -12 -7 -21 -16z"/>
<path d="M6128 7813 c-90 -138 -218 -341 -223 -354 -4 -10 33 -38 117 -91 146
-90 162 -99 206 -109 101 -22 192 84 148 172 -9 18 -16 33 -16 34 0 2 14 5 31
9 17 3 47 22 66 41 33 33 35 39 31 84 -3 26 -13 60 -24 75 -15 21 -207 158
-281 199 -7 4 -28 -19 -55 -60z m159 -109 c69 -43 83 -59 83 -94 0 -37 -32
-64 -65 -56 -32 8 -144 77 -145 89 0 10 59 97 65 97 3 0 31 -16 62 -36z m-34
-232 c47 -45 29 -112 -31 -112 -38 0 -187 97 -174 113 5 7 21 31 36 54 l26 43
58 -36 c31 -20 69 -48 85 -62z"/>
<path d="M5447 6489 c-211 -25 -372 -241 -337 -454 14 -88 54 -158 142 -252
127 -134 172 -263 149 -421 -17 -111 -55 -185 -150 -287 -45 -48 -91 -110
-106 -140 -91 -194 -10 -432 180 -526 231 -114 496 10 561 261 36 143 -4 265
-133 402 -113 122 -153 213 -153 358 0 144 41 238 153 357 51 55 89 106 108
147 82 175 24 384 -136 490 -84 56 -175 78 -278 65z"/>
<path d="M3985 6476 c-133 -42 -234 -151 -265 -285 -18 -77 -9 -185 22 -253
16 -34 58 -90 108 -144 91 -98 123 -153 145 -246 19 -81 19 -152 -1 -238 -19
-87 -67 -166 -155 -257 -42 -43 -80 -94 -97 -130 -24 -52 -27 -69 -27 -163 0
-96 3 -110 30 -165 150 -302 574 -302 720 0 27 54 29 70 30 165 0 99 -2 109
-32 170 -20 39 -62 96 -106 142 -128 135 -174 267 -150 434 15 107 58 188 155
290 101 107 130 168 136 280 3 65 0 95 -17 145 -39 118 -143 221 -255 254 -60
18 -186 18 -241 1z"/>
<path d="M3244 5772 c-70 -29 -134 -82 -175 -144 -119 -184 -48 -434 149 -526
50 -24 70 -27 157 -27 91 0 105 3 158 29 64 33 134 102 166 163 11 22 25 70
31 106 13 75 2 155 -32 221 -31 60 -105 133 -166 163 -48 23 -71 28 -152 30
-71 1 -106 -2 -136 -15z m204 -212 c133 -81 67 -293 -87 -277 -80 9 -135 69
-134 146 1 63 26 106 78 131 51 25 101 25 143 0z"/>
<path d="M6098 5766 c-101 -41 -172 -118 -207 -223 -51 -151 0 -313 127 -403
77 -56 136 -73 235 -69 101 5 175 39 243 111 71 76 98 145 98 248 0 72 -5 95
-29 148 -36 80 -121 162 -200 191 -79 30 -191 28 -267 -3z m202 -204 c143 -72
80 -295 -79 -279 -79 8 -133 67 -134 145 -2 114 111 186 213 134z"/>
<path d="M3338 3413 c-36 -59 -93 -154 -128 -210 -60 -98 -70 -133 -37 -133 8
0 61 77 117 170 56 94 105 170 108 170 2 0 1 -89 -3 -199 -6 -167 -5 -199 7
-204 8 -3 92 37 186 88 95 51 172 91 172 88 0 -3 -46 -82 -102 -176 -97 -161
-101 -170 -83 -183 22 -16 14 -28 180 249 55 92 103 167 106 167 4 0 9 -10 12
-22 3 -13 28 -96 56 -186 l51 -163 -20 -80 c-22 -84 -20 -99 10 -99 15 0 22
15 36 78 l18 77 138 140 c76 78 138 144 138 148 0 4 -10 7 -22 7 -16 0 -60
-38 -140 -117 -65 -64 -119 -115 -121 -113 -2 3 -25 76 -52 164 -32 101 -55
160 -65 163 -8 3 -29 12 -46 20 -31 16 -32 16 -185 -69 -165 -92 -215 -118
-223 -118 -3 0 -7 100 -8 222 -4 275 -6 277 -100 121z"/>
<path d="M6084 3461 c-29 -14 -214 -131 -262 -166 -5 -4 273 -424 280 -425 19
0 281 185 304 215 14 19 28 52 31 74 10 73 -56 141 -137 141 -20 0 -29 4 -24
11 12 20 5 88 -12 114 -36 55 -105 69 -180 36z m84 -103 c16 -16 15 -60 0 -81
-7 -9 -41 -34 -75 -56 l-62 -40 -30 48 c-17 26 -31 51 -31 54 0 4 28 25 63 47
63 40 112 51 135 28z m134 -165 c25 -22 23 -65 -4 -90 -34 -30 -131 -93 -145
-93 -10 0 -78 99 -71 104 11 9 117 75 133 84 29 16 66 14 87 -5z"/>
<path d="M5510 3190 c-134 -46 -170 -61 -170 -67 0 -3 36 -112 81 -242 l81
-237 148 51 c157 54 214 86 243 134 37 63 3 154 -68 180 -22 8 -41 15 -42 16
-1 0 5 21 14 45 14 41 14 48 0 82 -21 49 -69 78 -127 78 -25 -1 -95 -18 -160
-40z m177 -86 c28 -43 3 -73 -87 -106 -41 -15 -78 -28 -82 -28 -7 0 -38 86
-38 106 0 16 104 52 152 53 30 1 41 -4 55 -25z m87 -180 c22 -21 20 -60 -3
-83 -15 -15 -159 -71 -183 -71 -3 0 -15 27 -26 59 l-20 60 71 25 c84 29 139
32 161 10z"/>
<path d="M5186 3103 c-27 -4 -28 -6 -23 -47 3 -23 9 -100 13 -172 8 -149 -2
-184 -57 -203 -75 -26 -138 -2 -158 62 -8 27 -31 259 -31 318 0 16 -8 19 -54
19 -30 0 -57 -4 -60 -8 -3 -5 1 -84 9 -177 17 -194 33 -240 102 -289 87 -61
255 -40 322 41 47 57 53 99 39 293 l-12 170 -30 -1 c-17 -1 -44 -4 -60 -6z"/>
<path d="M4415 3082 c-49 -23 -69 -46 -85 -95 -26 -82 22 -141 157 -193 96
-37 119 -58 108 -94 -13 -42 -131 -40 -213 3 -29 15 -54 27 -56 27 -2 0 -13
-18 -25 -39 l-20 -40 32 -19 c116 -71 283 -78 351 -15 70 65 68 158 -4 208
-21 14 -73 39 -116 55 -104 39 -126 68 -83 111 18 18 29 21 76 16 30 -3 73
-15 97 -26 23 -12 45 -21 49 -21 4 0 16 18 27 40 l21 41 -58 26 c-78 36 -199
43 -258 15z"/>
</g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
